import React from 'react'

import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'

import { Avatar } from '@dentalux/ui-library-core/cjs/components/avatar'

import { Box, Link } from '@mui/material'

import PractitionerDetails from 'src/components/PractitionerDetails'
import getPractitionerRoles from 'src/helpers/getPractitionerRoles'

import * as S from '../../AppointmentView.styles'
import { ViewProps } from '../../AppointmentView.types'
import DentalTreatment from '../DentalTreatments'

const DesktopView = ({
  practitioner,
  treatments,
  treatmentTitle,
  LocationLink,
  clinic,
  labels,
  date,
  time,
}: ViewProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const hasDentalTreatments = treatments.length > 0 || treatmentTitle

  return (
    <>
      <S.SectionItem
        label={labels.date}
        value={
          <S.DateTime>
            {date} {time}
          </S.DateTime>
        }
      />

      {practitioner && (
        <>
          <S.Divider />

          <S.SectionItem
            label={
              <PractitionerDetails
                avatar={<Avatar src={practitioner?.imageUrl} />}
                title={practitioner.name}
                subTitle={getPractitionerRoles(practitioner, t, language)}
              />
            }
            value={
              <NextLink href={`/practitioner/${practitioner.referenceId}`} passHref>
                <Link target="_blank">{labels.viewProfile}</Link>
              </NextLink>
            }
          />
        </>
      )}

      {hasDentalTreatments && (
        <>
          <S.Divider />

          <S.SectionItem
            label={labels.treatment}
            value={
              <>
                {treatmentTitle && <S.Text>{treatmentTitle}</S.Text>}

                {treatments.length > 0 && (
                  <>
                    {treatmentTitle && <S.Text sx={{ pb: 0.5, pt: 1.5 }}>{labels.details}</S.Text>}

                    <DentalTreatment data={treatments} />
                  </>
                )}
              </>
            }
          />
        </>
      )}

      <S.Divider />

      <S.SectionItem
        label={labels.clinic}
        value={
          <>
            <S.Text>{clinic?.officialName}</S.Text>

            {!!LocationLink && <Box sx={{ pt: 1.5 }}>{LocationLink}</Box>}

            {clinic?.phoneNumber && <S.Text sx={{ pt: 1 }}>{clinic.phoneNumber}</S.Text>}
          </>
        }
      />
    </>
  )
}

export default DesktopView
